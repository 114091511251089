import { uploadImgUrl } from '@/common/utils/config.js';
export default {
  data() {
    return {
      uploadImgUrl: uploadImgUrl,
      dialogImageUrl: '',
      //预览图片地址
      img_list: [],
      //上传图片列表
      previewImgDialog: false,
      name: '',
      //

      releaseSuccessVisible: false,
      //发布成功弹框

      workList: [],
      //职位列表
      work: '',
      //职位名称
      work_id: '',
      //职位ID
      name: '',
      //姓名
      sex: 1,
      //
      sex_name: '',
      whatsapp: '',
      email: '',
      addr: '',
      //居住地址
      imageUrl: '',
      //简历图片
      imageUrl_id: '',
      isFlag: false,
      isShowDeleteIcon_gongsi: false
    };
  },
  mounted() {
    this.getWorkList();
  },
  methods: {
    // 获取职位
    getWorkList() {
      this.$http.workList().then(res => {
        if (res.code == 1) {
          this.workList = res.data;
        } else {
          this.$message.info(res.msg);
        }
      });
    },
    backClick() {
      this.$router.back();
    },
    // 图片删除
    handleRemove(file) {
      console.log(file, 3);
      this.imageUrl = '';
      console.log(this.imageUrl, '删除成功');
    },
    //鼠标进入事件
    mouseenterClick3() {
      if (this.imageUrl) {
        this.isShowDeleteIcon_gongsi = true;
      }
    },
    // 鼠标离开事件
    mouseleaveClick3() {
      if (this.imageUrl) {
        this.isShowDeleteIcon_gongsi = false;
      }
    },
    handleAvatarSuccess3(res, file) {
      console.log('上传成功');
      this.imageUrl = file.response.data[0].path;
      this.imageUrl_id = file.response.data[0].id;
      console.log(file);
      console.log(this.imageUrl, '上传成功');
    },
    // 上传之前
    beforeAvatarUpload(file) {
      console.log("jhhhh");
      const fileName = file.name;
      const fileType = fileName.substring(fileName.lastIndexOf('.'));
      console.log("文件类型", fileType);
      if (fileType === '.jpg' || fileType === '.png' || fileType === '.jpeg' || fileType === '.bmp' || fileType === '.gif') {} else {
        this.$message.error(this.$t('pleaseUploadTheCorrectImageType'));
        return false;
      }
    },
    // 上传图片成功
    onChange(file, fileList) {
      console.log(file);
      console.log(fileList);
      this.img_list = fileList;
    },
    // 提交
    submitClick() {
      if (this.isFlag) return;

      // let image = this.img_list.map(item => {
      // 	if (Object.prototype.hasOwnProperty.call(item, "response")) {
      // 		if (item.response.code == 1) {
      // 			return item.response.data[0].id;
      // 		}
      // 	}
      // }).join(',');

      // if (!this.name) {
      // 	console.log(1);
      // 	this.$message.error(this.$t('completeInfor'));
      // 	return;
      // }
      // if (!this.sex) {
      // 	console.log(2);

      // 	this.$message.error(this.$t('completeInfor'));
      // 	return;
      // }
      // if (!this.whatsapp) {
      // 	console.log(3);

      // 	this.$message.error(this.$t('completeInfor'));
      // 	return;
      // }
      // if (!this.email) {
      // 	console.log(4);

      // 	this.$message.error(this.$t('completeInfor'));
      // 	return;
      // }

      // if (!this.addr) {
      // 	console.log(5);

      // 	this.$message.error(this.$t('completeInfor'));
      // 	return;
      // }
      if (!this.work_id) {
        this.$message.error(this.$t('choosePostType'));
        return;
      }
      if (!this.imageUrl) {
        this.$message.error(this.$t('pleaseUploadResume'));
        return;
      }
      this.isFlag = true;
      let data = {
        // name: this.name, //姓名
        // sex: this.sex, //
        // whatsapp: this.whatsapp,
        // email: this.email,
        // addr: this.addr,
        work_id: this.work_id,
        image: this.imageUrl_id
      };
      console.log(data);
      this.$http.addUserWork(data).then(res => {
        if (res.code == 1) {
          this.releaseSuccessVisible = true;
          this.isFlag = false;
          this.clearData();
        } else {
          this.isFlag = false;
          this.$message.error(res.msg);
        }
      });
    },
    // 清除数据
    clearData() {
      this.name = '';
      this.sex = 1;
      this.work_id = '';
      this.whatsapp = '';
      this.email = '';
      this.addr = '';
      this.$refs.uploadImg.clearFiles();
    }
  }
};