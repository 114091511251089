var render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return _c('div', {
    staticClass: "all_detail"
  }, [_c('div', {
    staticClass: "detail"
  }, [_c('div', {
    staticClass: "person_infor"
  }, [_c('div', {
    staticClass: "login_top"
  }, [_c('div', {
    staticClass: "login_title",
    on: {
      "click": _vm.backClick
    }
  }, [_vm._v(_vm._s(_vm.$t('talent')))])]), _c('div', {
    staticClass: "tip"
  }, [_c('img', {
    attrs: {
      "src": require("@/assets/mycenter/zhaopin_icon.png"),
      "alt": ""
    }
  }), _c('span', [_vm._v(" " + _vm._s(_vm.$t('talentHireTitle')) + " ")])]), _c('div', {
    staticClass: "all_list"
  }, [_c('div', {
    staticClass: "list_item"
  }, [_c('div', {
    staticClass: "list_item_top"
  }, [_vm._v(" " + _vm._s(_vm.$t('postType')) + " ")]), _c('div', {
    staticClass: "list_item_bottom"
  }, [_c('el-select', {
    attrs: {
      "placeholder": _vm.$t('release.pleaseSelect'),
      "clearable": ""
    },
    model: {
      value: _vm.work_id,
      callback: function ($$v) {
        _vm.work_id = $$v;
      },
      expression: "work_id"
    }
  }, _vm._l(_vm.workList, function (item) {
    return _c('el-option', {
      key: item.id,
      attrs: {
        "label": item.name,
        "value": item.id
      }
    });
  }), 1)], 1)]), _c('div', {
    staticClass: "list_item",
    staticStyle: {
      "border-bottom": "none"
    }
  }, [_c('div', {
    staticClass: "list_item_top"
  }, [_vm._v(" " + _vm._s(_vm.$t('resumeUpload')) + " ")]), _c('div', {
    staticClass: "infor_item_right"
  }, [_c('el-upload', {
    attrs: {
      "action": _vm.uploadImgUrl,
      "show-file-list": false,
      "list-type": "picture-card",
      "on-success": _vm.handleAvatarSuccess3,
      "before-upload": _vm.beforeAvatarUpload
    }
  }, [_c('div', {
    staticClass: "upload_img",
    on: {
      "mouseenter": _vm.mouseenterClick3,
      "mouseleave": _vm.mouseleaveClick3
    }
  }, [_vm.imageUrl ? _c('img', {
    staticClass: "avatar",
    attrs: {
      "src": _vm.imageUrl
    }
  }) : _vm._e(), _vm.imageUrl && _vm.isShowDeleteIcon_gongsi ? _c('span', {
    staticClass: "el-upload-action",
    on: {
      "click": function ($event) {
        $event.stopPropagation();
        return _vm.handleRemove();
      }
    }
  }, [_c('i', {
    staticClass: "el-icon-delete"
  })]) : _vm._e(), !_vm.imageUrl ? _c('i', {
    staticClass: "el-icon-plus",
    attrs: {
      "slot": "default"
    },
    slot: "default"
  }) : _vm._e()])])], 1)]), _c('div', {
    staticClass: "btn",
    on: {
      "click": _vm.submitClick
    }
  }, [_c('div', [_vm._v(_vm._s(_vm.$t('submit')))])])])])]), _c('div', {
    staticClass: "success_dialog"
  }, [_c('el-dialog', {
    attrs: {
      "title": "",
      "visible": _vm.releaseSuccessVisible,
      "width": "30%"
    },
    on: {
      "update:visible": function ($event) {
        _vm.releaseSuccessVisible = $event;
      }
    }
  }, [_c('div', {
    staticClass: "release_success"
  }, [_c('div', {
    staticClass: "success_img"
  }, [_c('img', {
    attrs: {
      "src": require("@/assets/mycenter/release_success.png")
    }
  })]), _c('div', {
    staticClass: "success_title"
  }, [_vm._v(" " + _vm._s(_vm.$t('yourResumeHasBeenSubmittedSuccessfully')) + " ")]), _c('div', {
    staticClass: "success_tip"
  }, [_vm._v(_vm._s(_vm.$t('thePlatformStaffWillContactYouAfterAdmission')))])])])], 1)]);
};
var staticRenderFns = [];
export { render, staticRenderFns };